<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <validation-observer>
      <v-card>
        <v-card-title primary-title />
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-row>
                <v-col
                  class="text-button align-self-center pt-0"
                  cols="4"
                >
                  <div>
                    <span class="font-weight-bold">{{
                      $t("messages.user_id")
                    }}</span>
                  </div>
                </v-col>
                <v-col
                  class="text-button align-self-center pb-0 pt-0"
                  cols="7"
                >
                  <div>
                    <validation-provider v-slot="{ errors }">
                      <v-text-field
                        id="username"
                        v-model="username"
                        clearable
                        name="username"
                        outlined
                        :disabled="true"
                        :error-messages="errors[0] ? errors[0] : checkUserName"
                      />
                    </validation-provider>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="userType !== 3">
                <v-col
                  class="text-button align-self-center pt-0"
                  cols="4"
                >
                  <div>
                    <span class="font-weight-bold">{{
                      $t("messages.password")
                    }}</span>
                  </div>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    color="primary"
                    :disabled="!isActive"
                    @click="resetPass"
                  >
                    {{ $t("button.resetPassword") }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="3"
                  class="text-button align-self-center pb-0 pt-0"
                >
                  <v-text-field
                    ref="newPass"
                    v-model="oneTimePass"
                    :disabled="!isActive"
                    name="oneTimePass"
                    :type="showPass ?'text':'password'"
                    :append-icon="showPass ?'mdi-eye':'mdi-eye-off'"
                    outlined
                    readonly
                    @click:append="showPass=!showPass"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="2">
                  <v-btn
                    v-clipboard:copy="oneTimePass"
                    v-clipboard:success="onSuccess"
                    v-clipboard:error="onError"
                    :disabled="!isActive"
                  >
                    {{ alertCopy.text }}
                  </v-btn>
                </v-col>
              </v-row>
              <div v-else>
                <v-row>
                  <v-col
                    class="text-button align-self-center pt-0"
                    cols="4 "
                  >
                    <div>
                      <span class="font-weight-bold">{{
                        $t("messages.password")
                      }}</span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <div>
                      <validation-provider
                        v-slot="{ errors }"
                        ref="confirm"
                        name="confirm"
                      >
                        <v-text-field
                          v-model="password"
                          :disabled="!isActive"
                          clearable
                          type="password"
                          outlined
                          :error-messages="
                            errors[0] ? errors[0] : checkPassword
                          "
                        />
                      </validation-provider>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <div>
                      <span class="font-weight-bold">{{
                        $t("messages.rePassword")
                      }}</span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <div>
                      <validation-provider v-slot="{ errors }">
                        <v-text-field
                          v-model="rePassword"
                          :disabled="!isActive"
                          clearable
                          type="password"
                          outlined
                          :error-messages="
                            errors[0] ? errors[0] : rePasswordCheck
                          "
                        />
                      </validation-provider>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-row
                v-for="(value, index) in fields1"
                :key="index"
                class="align"
              >
                <v-col
                  v-if="value.type !== 'spacer'"
                  class="text-button align-self-center pt-0"
                  cols="4"
                >
                  <div>
                    <span class="font-weight-bold">{{
                      $t("messages." + value.label)
                    }}</span>
                    <span class="red--text">
                      {{
                        value.validate
                          ? value.validate.includes("required")
                            ? "*"
                            : ""
                          : ""
                      }}</span>
                  </div>
                </v-col>
                <v-col
                  class="text-button align-self-center pb-0 pt-0"
                  cols="7"
                >
                  <v-textarea
                    v-if="value.type === 'textarea'"
                    v-model="value.value"
                    :placeholder="$t(value.placeholder)"
                    clearable
                    outlined
                    :disabled="!isActive"
                  />
                  <validation-provider
                    v-else-if="value.type === 'date-text'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <div v-if="'menu' in value">
                      <v-row>
                        <v-col cols="8">
                          <v-text-field
                            v-model="value.value"
                            :name="value.key"
                            outlined
                            autocomplete="off"
                            :error-messages="
                              errors[0] ? errors[0] : value.customerValidate
                            "
                            :placeholder="$t(value.placeholder)"
                            :disabled="!isActive"
                            readonly
                            @keydown="checkDate"
                          >
                            <template v-slot:append />
                          </v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-menu
                            :ref="'menu' + value.key"
                            v-model="value.menu"
                            :disabled="!isActive"
                            :close-on-content-click="false"
                            :return-value.sync="value.value"
                            transition="scale-transition"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="mt-4 mr-3"
                                v-bind="attrs"
                                left
                                v-on="on"
                              >
                                mdi-calendar
                              </v-icon>
                            </template>
                            <v-date-picker
                              v-model="value.value"
                              no-title
                              scrollable
                              :max="value.key === 'dob' ? value.max_date : ''"
                            >
                              <v-spacer />
                              <v-btn
                                text
                                color="primary"
                                @click="value.menu = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="saveValueDate(value)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :ref="'date' + value.key"
                            disabled
                            outlined
                            :value="
                              value.key === 'dob' && value.value.length > 0
                                ? Math.round(
                                  new Date().getFullYear() -
                                    new Date(value.value).getFullYear()
                                )
                                : '0'
                            "
                          />
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-text-field
                        v-model="value.value"
                        :name="value.key"
                        clearable
                        outlined
                        :error-messages="
                          errors[0] ? errors[0] : value.customerValidate
                        "
                        :placeholder="$t(value.placeholder)"
                        :disabled="!isActive"
                        @keydown="checkDate"
                      />
                    </div>
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'select'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-select
                      v-model="value.value"
                      :name="value.key"
                      outlined
                      :error-messages="
                        errors[0] ? errors[0] : value.customerValidate
                      "
                      :items="value.items"
                      item-text="text"
                      item-value="value"
                      :placeholder="$t(value.placeholder)"
                      :disabled="!isActive"
                    />
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'text'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-text-field
                      v-model="value.value"
                      :disabled="value.disable || !isActive"
                      :placeholder="$t(value.placeholder)"
                      :name="value.key"
                      outlined
                      :error-messages="
                        errors[0] ? errors[0] : value.customerValidate
                      "
                      clearable
                      @keydown="handleResetValidate(value)"
                    />
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'checkbox'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-row>
                      <v-col cols="3">
                        <v-checkbox
                          v-model="value.value"
                          :disabled="true"
                          :name="value.key"
                          :error-messages="errors[0]"
                          color="#0F56B3"
                        />
                      </v-col>
                    </v-row>
                  </validation-provider>
                  <validation-provider
                    v-else
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-radio-group
                      v-model="value.value"
                      row
                      @change="handleShowDialogStatus(value.key)"
                    >
                      <v-radio
                        label="在職"
                        color="primary"
                        :value="true"
                      />
                      <v-radio
                        label="退職"
                        color="primary"
                        :value="false"
                      />
                    </v-radio-group>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row
                v-for="(value, index) in fields2"
                :key="index"
                class="align"
              >
                <v-col
                  v-if="value.type !== 'spacer'"
                  class="text-button align-self-center pt-0"
                  cols="4"
                >
                  <div>
                    <span class="font-weight-bold">{{
                      $t("messages." + value.key)
                    }}</span>
                    <span class="red--text">
                      {{
                        value.validate
                          ? value.validate.includes("required")
                            ? "*"
                            : ""
                          : ""
                      }}</span>
                  </div>
                </v-col>
                <v-col
                  class="text-button align-self-center pb-0 pt-0"
                  cols="7"
                >
                  <v-textarea
                    v-if="value.type === 'textarea'"
                    v-model="value.value"
                    :placeholder="$t(value.placeholder)"
                    clearable
                    outlined
                  />
                  <validation-provider
                    v-else-if="value.type === 'date-text'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <div v-if="'menu' in value">
                      <v-row>
                        <v-col cols="8">
                          <v-text-field
                            v-model="value.value"
                            :name="value.key"
                            outlined
                            autocomplete="off"
                            :error-messages="errors[0]"
                            :placeholder="$t(value.placeholder)"
                            :disabled="value.disable || !isActive"
                            readonly
                            @keydown="checkDate"
                          >
                            <template v-slot:append />
                          </v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-menu
                            :ref="'menu' + value.key"
                            v-model="value.menu"
                            :disabled="value.disable || !isActive"
                            :close-on-content-click="false"
                            :return-value.sync="value.value"
                            transition="scale-transition"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="mt-4 mr-3"
                                v-bind="attrs"
                                left
                                v-on="on"
                              >
                                mdi-calendar
                              </v-icon>
                            </template>
                            <v-date-picker
                              v-model="value.value"
                              no-title
                              scrollable
                            >
                              <v-spacer />
                              <v-btn
                                text
                                color="primary"
                                @click="value.menu = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="saveValueDate(value)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :ref="'date' + value.key"
                            disabled
                            outlined
                            :value="expiryDate(value.value)"
                          />
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-text-field
                        v-model="value.value"
                        :name="value.key"
                        clearable
                        outlined
                        :error-messages="errors[0]"
                        :placeholder="$t(value.placeholder)"
                        :disabled="value.disable || !isActive"
                        @keydown="checkDate"
                      />
                    </div>
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'autocomplete'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-autocomplete
                      v-model="value.value"
                      :name="value.key"
                      :items="value.items"
                      :placeholder="$t(value.placeholder)"
                      :error-messages="errors[0]"
                      :disabled="value.disable || !isActive"
                      item-text="text"
                      item-value="value"
                      clearable
                      dense
                      single-line
                      outlined
                    />
                  </validation-provider>
                  <validation-provider
                    v-else-if="value.type === 'text'"
                    v-slot="{ errors }"
                    :name="value.key"
                    :rules="value.validate ? value.validate : ''"
                  >
                    <v-text-field
                      v-model="value.value"
                      :disabled="value.disable || !isActive"
                      :placeholder="$t(value.placeholder)"
                      :name="value.key"
                      outlined
                      :error-messages="errors[0]"
                      clearable
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <div class="custom-margin-top">
                <v-row
                  v-for="(value, index) in fields3"
                  :key="index"
                  class="align"
                >
                  <v-col
                    v-if="value.type !== 'spacer'"
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <div>
                      <span class="font-weight-bold">{{
                        $t("messages." + value.key)
                      }}</span>
                      <span class="red--text">
                        {{
                          value.validate
                            ? value.validate.includes("required")
                              ? "*"
                              : ""
                            : ""
                        }}</span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <v-textarea
                      v-if="value.type === 'textarea'"
                      v-model="value.value"
                      :placeholder="$t(value.placeholder)"
                      clearable
                      outlined
                      :disabled="!isActive"
                    />
                    <validation-provider
                      v-else-if="value.type === 'date-text'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <div v-if="'menu' in value">
                        <v-row>
                          <v-col cols="8">
                            <v-text-field
                              v-model="value.value"
                              :name="value.key"
                              outlined
                              autocomplete="off"
                              :error-messages="
                                errors[0] ? errors[0] : value.customerValidate
                              "
                              :placeholder="$t(value.placeholder)"
                              :disabled="value.disable || !isActive"
                              readonly
                              @keydown="checkDate"
                            >
                              <template v-slot:append />
                            </v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <v-menu
                              :ref="'menu' + value.key"
                              v-model="value.menu"
                              :disabled="value.disable || !isActive"
                              :close-on-content-click="false"
                              :return-value.sync="value.value"
                              transition="scale-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="mt-4 mr-3"
                                  v-bind="attrs"
                                  left
                                  v-on="on"
                                >
                                  mdi-calendar
                                </v-icon>
                              </template>
                              <v-date-picker
                                v-model="value.value"
                                no-title
                                scrollable
                              >
                                <v-spacer />
                                <v-btn
                                  text
                                  color="primary"
                                  @click="value.menu = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="saveValueDate(value)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              :ref="'date' + value.key"
                              disabled
                              outlined
                              :value="expiryDate(value.value)"
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <div v-else>
                        <v-text-field
                          v-model="value.value"
                          :name="value.key"
                          clearable
                          outlined
                          :error-messages="
                            errors[0] ? errors[0] : value.customerValidate
                          "
                          :placeholder="$t(value.placeholder)"
                          :disabled="value.disable || !isActive"
                          @keydown="checkDate"
                        />
                      </div>
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'select'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-select
                        v-if="value.key === 'visaId'"
                        v-model="visaId"
                        :name="value.key"
                        outlined
                        :error-messages="
                          errors[0] ? errors[0] : value.customerValidate
                        "
                        :items="value.items"
                        item-text="text"
                        item-value="value"
                        :placeholder="$t(value.placeholder)"
                        :disabled="value.disable || !isActive"
                        @change="selectDocs(value)"
                      />
                      <v-select
                        v-else
                        v-model="value.value"
                        :name="value.key"
                        outlined
                        :error-messages="
                          errors[0] ? errors[0] : value.customerValidate
                        "
                        :items="value.items"
                        item-text="text"
                        item-value="value"
                        :placeholder="$t(value.placeholder)"
                        :disabled="value.disable || !isActive"
                      />
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'text'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-text-field
                        v-if="value.key === 'language'"
                        v-model="language"
                        :disabled="!isActive"
                        :placeholder="$t(value.placeholder)"
                        :name="value.key"
                        outlined
                        :error-messages="
                          errors[0] ? errors[0] : value.customerValidate
                        "
                        clearable
                        @keydown="handleResetValidate(value)"
                      />
                      <v-text-field
                        v-else
                        v-model="value.value"
                        :disabled="value.disable || !isActive"
                        :placeholder="$t(value.placeholder)"
                        :name="value.key"
                        outlined
                        :error-messages="
                          errors[0] ? errors[0] : value.customerValidate
                        "
                        clearable
                        @keydown="handleResetValidate(value)"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <div
                class="pa-3 mt-5"
                style="border: 1px solid; border-radius: 10px"
              >
                <v-row class="d-flex">
                  <v-col>
                    <span class="title-bank">{{
                      $t("messages.accountInformation")
                    }}</span>
                  </v-col>
                  <v-col
                    v-if="userType !== 3"
                    class="d-flex justify-end"
                  >
                    <v-icon
                      v-if="isActive"
                      @click="openDialogBank"
                    >
                      mdi-pencil
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(value, index) in fields4"
                  :key="index"
                  class="align"
                >
                  <v-col
                    v-if="value.type !== 'spacer'"
                    class="text-button align-self-center pt-0"
                    cols="4"
                  >
                    <div>
                      <span class="font-weight-bold">{{
                        $t("messages." + value.key)
                      }}</span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-text-field
                        v-model="value.value"
                        :disabled="value.disabled || !isActive"
                        :placeholder="$t(value.placeholder)"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0]"
                        clearable
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-card
            v-if="parttimeEmployee.isFixed"
            class="mt-10"
          >
            <v-card-text>
              <v-row>
                <v-col
                  class="text-button align-self-center pt-0"
                  cols="1"
                >
                  <div>
                    <span class="font-weight-bold">
                      {{
                        $t('messages.customerName')
                      }}
                    </span>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="parttimeEmployee.customerId"
                    name="customerName"
                    :items="[{...parttimeEmployee.customer}]"
                    :item-text="item => item.code == null ? item.user.name : item.code +' - '+ item.user.name"
                    item-value="id"
                    readonly
                    solo
                  />
                </v-col>
              </v-row>
              <v-row
                style="border-radius:10px; border:1px solid #25ACD8"
              >
                <v-col cols="11">
                  <v-row
                    v-for="(item, index) in parttimeEmployee.fixedShifts"
                    :key="index"
                    style="border-radius:10px; border:1px solid #25ACD8"
                    class=" d-flex justify-space-beetween"
                  >
                    <v-col
                      class="text-button align-self-center pt-0"
                      cols="1"
                    >
                      <div>
                        <span class="font-weight-bold">
                          {{
                            $t('messages.workDay')
                          }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <v-btn-toggle
                        v-model="item.workdate"
                        multiple
                      >
                        <v-btn
                          v-for="(i, index1) in itemsDay"
                          :key="index1"
                          class="mr-1 disable-events"
                          color="#81bfff"
                          readonly
                        >
                          {{ i.text }}
                        </v-btn>
                      </v-btn-toggle>
                      <!-- <label
                        v-if="item.workDate < 1 && item.errorWorkDate"
                        class="red--text"
                      >
                        {{ $t('validator.required') }}
                      </label> -->
                    </v-col>
                    <v-col
                      class="text-button align-self-center pt-0"
                      cols="1"
                      offset="1"
                    >
                      <div>
                        <span class="font-weight-bold">
                          {{
                            $t('messages.jobName')
                          }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="item.jobId"
                        item-text="name"
                        item-value="id"
                        :items="[{...item.job}]"
                        readonly
                        name="jobId"
                        solo
                      />
                    </v-col>
                    <v-col
                      class="text-button align-self-center pt-0"
                      cols="1"
                    >
                      <div>
                        <span class="font-weight-bold">
                          {{
                            $t('messages.shift')
                          }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        v-model="item.shiftId"
                        :items="[{...item.shift}]"
                        :item-text="s => s.shiftStartAt + ' ~ ' + s.shiftEndAt"
                        item-value="id"
                        readonly
                        solo
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row class="d-flex">
            <v-col cols="4">
              <v-chip
                class="pa-16 align-center chip ml-0"
                color="#FFF4DE"
                text-color="orange"
                @click="
                  $router.push('/parttimer-history/' + $route.params.id)
                "
              >
                {{ $t("button.history") }}
                <v-icon
                  right
                  x-large
                >
                  mdi-briefcase-variant
                </v-icon>
              </v-chip>
            </v-col>

            <v-col
              cols="4"
              class="d-flex justify-end"
            >
              <div
                class="pa-16 align-center chip ml-0"
                style="background: #c9f7f5; color: #1bc4bd"
                @click="dialogCheckFile = true"
              >
                <v-row class="d-flex">
                  <p class="1 && mr-auto">
                    {{ $t("button.confirmFile") }}
                  </p>
                  <p>{{ lengthFiles }}</p>
                </v-row>
                <v-row>
                  <v-progress-linear
                    background-color="rgba(105, 147, 255, 0.2)"
                    color="#1bc4bd"
                    :value="processCountFile"
                  />
                </v-row>
              </div>
            </v-col>

            <v-col
              v-if="docsList.length > 0"
              cols="4"
              class="d-flex justify-end"
            >
              <div
                class="pa-16 align-center chip ml-0"
                style="background: #e1e9ff; color: #6993ff"
                @click="dialogCheckDoc = true"
              >
                <v-row class="d-flex">
                  <p class="1 && mr-auto">
                    {{ $t("button.confirmDoc") }}
                  </p>
                  <p>{{ lengthDocs }}</p>
                </v-row>
                <v-row>
                  <v-progress-linear
                    background-color="rgba(105, 147, 255, 0.2)"
                    color="#6993FF"
                    :value="processCount"
                  />
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row align="center" />
        </v-card-text>
        <v-card-actions />
      </v-card>
      <!--button-->
      <v-row class="d-flex mt-3">
        <!-- <v-col
          cols="2"
          :class="1 && 'mr-auto'"
        >
          <v-btn
            large
            block
            color="info"
            @click="dialogOcr = true"
          >
            <span class="text-h5 pr-3 pl-3"> {{ $t("button.ocr") }}</span>
          </v-btn>
        </v-col> -->
        <v-col
          v-if="showButton === true"
          cols="2"
          class="d-flex justify-space-between"
        >
          <v-btn
            large
            block
            color="info"
            type="submit"
            @click="onSubmit"
          >
            <span class="text-h5 pr-3 pl-3"> {{ $t("messages.save") }}</span>
          </v-btn>
        </v-col>
        <v-col
          v-if="showButton === true"
          cols="2"
        >
          <v-btn
            large
            block
            color="info"
            type="submit"
            @click="cancelSubmit"
          >
            <span class="text-h5 pr-3 pl-3"> {{ $t("button.cancel") }}</span>
          </v-btn>
        </v-col>
        <v-col
          v-else
          cols="1"
        >
          <v-chip
            color="#5CA6D2"
            dark
            large
            style="
              background-color: rgb(92, 166, 210);
              border-radius: 10px;
              width: 100px;
              border-color: rgb(92, 166, 210);
            "
            @click="showButton = true"
          >
            <v-icon
              dark
              x-large
              style="padding-left: 35px"
            >
              mdi-pencil-outline
            </v-icon>
          </v-chip>
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-space-between"
        >
          <v-btn
            large
            block
            color="info"
            @click="dialogDelete = true"
          >
            <span class="text-h5 pr-3 pl-3"> {{ $t("messages.delete") }}</span>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
            large
            block
            color="info"
          >
            <span
              class="text-h5 pr-3 pl-3"
              @click="$router.push('/parttimer-list/')"
            >
              {{ $t('messages.close') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t("messages.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <!-- ocr dialog -->
    <v-dialog
      v-model="dialogOcr"
      width="500"
      persistent
    >
      <qrcode />
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="font-weight-bold text-h4">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="1">
              <v-icon
                large
                color="blue darken-2"
              >
                mdi-delete-outline
              </v-icon>
            </v-col>
            <v-col cols="8">
              この操作は元に戻りません。 本当に削除してよろしいですか？
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-spacer />

          <v-btn
            class="text-h5"
            dark
            color="#E43E08"
            @click="deleteItem"
          >
            {{ $t("messages.delete") }}
          </v-btn>
          <v-btn
            class="text-h5"
            @click="dialogDelete = false"
          >
            {{ $t("messages.cancel") }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- bank information dialog  -->
    <v-dialog
      v-model="dialogBank"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <span>{{ $t("messages.managerId") }}</span>
                {{ parttimeEmployee.manageId }}
              </v-col>
              <v-col cols="6">
                <span>{{ $t("messages.name") }}</span>
                {{ parttimeEmployee.name }}
              </v-col>
            </v-row>
            <v-row
              v-for="(value, index) in fieldsBank"
              :key="index"
              class="align"
            >
              <v-col
                v-if="value.type !== 'spacer'"
                class="text-button align-self-center pt-0"
                cols="4"
              >
                <div>
                  <span class="font-weight-bold">{{
                    $t("messages." + value.key)
                  }}</span>
                  <span class="red--text">
                    {{
                      value.validate
                        ? value.validate.includes("required")
                          ? "*"
                          : ""
                        : ""
                    }}</span>
                </div>
              </v-col>
              <v-col
                class="text-button align-self-center pb-0 pt-0"
                cols="7"
              >
                <v-text-field
                  v-model="value.value"
                  :placeholder="$t(value.placeholder)"
                  :name="value.key"
                  outlined
                  clearable
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#5CA6D2"
            large
            @click="dialogOcr = true"
          >
            {{ $t("button.ocr") }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            large
            @click="saveBank"
          >
            {{ $t("button.changeBankInfo") }}
          </v-btn>
          <v-btn
            color="dark darken-1"
            large
            @click="dialogBank = false"
          >
            {{ $t("button.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCheckDoc"
      max-width="400px"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row
              v-for="(value, index) in docsList"
              :key="index"
              class="align"
            >
              <v-col
                class="text-button align-self-center pb-0 pt-0"
                cols="8"
              >
                <v-checkbox
                  v-model="value.isFulfiled"
                  :disabled="!isActive"
                  :label="value.name"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!isActive"
            color="blue darken-1"
            large
            @click="saveDocs"
          >
            {{ $t("button.save") }}
          </v-btn>
          <v-btn
            color="dark darken-1"
            large
            @click="dialogCheckDoc = false"
          >
            {{ $t("button.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-status
      :dialog-status="dialogStatus"
      @save="hanldeStatus"
      @closeDialogStatus="hanldeStatus"
    />
    <dialog-check-file
      :dialog-check-file="dialogCheckFile"
      :is-add="false"
      :action="action"
      @closeDialogFile="closeDialogFile"
      @handleUpdateLengthFiles="hanldleUpdateLengFile"
    />
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { required, email, min } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import DialogCheckFile from '../../components/DialogCheckFile'
  import Qrcode from './Qrcode'
  import DialogStatus from '../../components/DialogStatus'

  export default {
    name: 'ParttimerDetail',
    components: {
      DialogCheckFile,
      ValidationProvider,
      ValidationObserver,
      Qrcode,
      DialogStatus,
    },
    data () {
      return {
        isAction: false,
        bufferValue: 10,
        username: '',
        password: '',
        rePassword: '',
        oneTimePass: '',
        showButton: false,
        rePasswordCheck: '',
        age: '',
        residenceCardAvailable: '',
        campusCardAvailable: '',
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        fields1: [
          {
            categoriesKey: 1,
            label: 'manageIdPartime',
            key: 'manageId',
            value: '',
            type: 'text',
            validate: 'required',
            disable: true,
          },
          {
            label: 'name',
            key: 'name',
            value: '',
            type: 'text',
            validate: 'required|requiredTypeName',
            customerValidate: '',
          },
          {
            label: 'furiganaName',
            key: 'furiganaName',
            value: '',
            type: 'text',
            validate: 'required|katakanaType',
            customerValidate: '',
          },
          {
            label: 'dob',
            key: 'dob',
            value: '',
            type: 'date-text',
            max_date: moment().format('YYYY-MM-DD'),
            validate: 'required|dateDob|rangeDate',
            placeholder: 'placeholder.dob',
            menu: false,
            customerValidate: '',
          },
          {
            label: 'sex',
            key: 'sex',
            items: [
              { value: 1, text: '男' },
              { value: 0, text: '女' },
            ],
            value: '1',
            type: 'select',
            validate: 'required',
            customerValidate: '',
          },
          {
            label: 'email',
            key: 'email',
            value: '',
            type: 'text',
            validate: 'email',
            customerValidate: '',
          },
          {
            label: 'contactPhone',
            key: 'contactPhone',
            value: '',
            type: 'text',
            customerValidate: '',
            validate: '',
          },
          {
            label: 'contactAddress',
            key: 'contactAddress',
            value: '',
            type: 'text',
            customerValidate: '',
          },
          {
            label: 'status',
            key: 'isActive',
            value: null,
            type: 'radio',
            customerValidate: '',
          },
          {
            label: 'fixed',
            key: 'isFixed',
            value: false,
            type: 'checkbox',
            customerValidate: '',
          },
        ],
        fields2: [
          {
            key: 'collegeId',
            items: [],
            value: '',
            validate: '',
            type: 'autocomplete',
            disable: false,
            placeholder: 'placeholder.schoolNamePlaceholder',
          },
          {
            key: 'campusCardValidDate',
            value: '',
            type: 'date-text',
            disable: false,
            max_date: moment().format('YYYY-MM-DD'),
            validate: 'dateResidenceCard',
            placeholder: 'placeholder.dob',
            menu: false,
          },
          {
            key: 'serviceInfo',
            value: '',
            type: 'text',
            disable: false,
            customerValidate: '',
          },
        ],
        fields3: [
          {
            key: 'language',
            value: '',
            type: 'text',
            validate: 'required',
            customerValidate: '',
          },
          {
            key: 'visaId',
            items: [],
            value: '',
            type: 'select',
            validate: '',
            customerValidate: '',
            disable: false,
          },
          {
            key: 'residenceCardId',
            value: '',
            type: 'text',
            customerValidate: '',
            disable: false,
          },
          {
            key: 'residenceCardValidDate',
            value: '',
            type: 'date-text',
            max_date: moment().format('YYYY-MM-DD'),
            validate: 'dateResidenceCard',
            placeholder: 'placeholder.dob',
            menu: false,
            customerValidate: '',
            disable: false,
          },
        ],
        fields4: [
          {
            key: 'bank',
            value: '',
            type: 'text',
            disabled: true,
          },
          {
            key: 'bankBranch',
            value: '',
            type: 'text',
            disabled: true,
          },
          {
            key: 'cardNumber',
            value: '',
            type: 'text',
            disabled: true,
          },
          {
            key: 'accountHolder',
            value: '',
            type: 'text',
            disabled: true,
          },
        ],
        fieldsBank: [
          {
            key: 'bank',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            key: 'bankBranch',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            key: 'cardNumber',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            key: 'accountHolder',
            value: '',
            type: 'text',
            validate: 'required',
          },
          {
            key: 'reason',
            value: '',
            type: 'text',
            validate: 'required',
          },
        ],
        docsList: [],
        dialogOcr: false,
        dialogBank: false,
        dialogDelete: false,
        dialogCheckDoc: false,
        dialogCheckFile: false,
        lengthDocs: '0',
        lengthFiles: '0',
        processCount: 0,
        processCountFile: 40,
        checkUserName: '',
        checkPassword: '',
        checkCollegeId: '',
        userType: 0,
        alertCopy: {
          text: this.$t('button.copy'),
        },
        showPass: false,
        fieldKey: '',
        dialogStatus: false,
        isActive: true,
        action: '',
        itemsDay: [
          { value: '0', text: '日' },
          { value: '1', text: '月' },
          { value: '2', text: '火' },
          { value: '3', text: '水' },
          { value: '4', text: '木' },
          { value: '5', text: '金' },
          { value: '6', text: '土' },
        ],
        language: '',
        visaId: '',
        visaStudentId: '',
      }
    },
    computed: {
      ...get('parttimer', [
        'message',
        'status',
        'error',
        'parttimeEmployee',
        'colleges',
        'dataOcrSave',
        'resetPassword',
        'parttimerVisaList',
        'newPassword',
      ]),
      ...get('employee', [
        'messageEmployee',
      ]),
      ...get('visa', ['listVisa']),
    },
    watch: {
      rePassword (value) {
        this.rePasswordCheck =
          value === this.password
            ? ''
            : this.$t('validator.confirm_password_invalid')
      },
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },

      message (value) {
        if (value === 'success') {
          this.showSnackBar('messages.success')
        } else if (value === 'successChangePass') {
          this.showSnackBar('messages.successChangePass')
        } else if (value === 'deleteSuccess') {
          this.$router.push({
            path: '/parttimer-list',
            query: { success: true },
          })
        } else if (value === 'updateSuccess') {
          this.action = 'updateSuccess'
        }
      },
      messageEmployee (value) {
        if (value === 'success') {
          this.showSnackBar('messages.success')
        }
      },
      parttimeEmployee (value) {
        this.username = value.username ? value.username : ''

        for (let [key, val] of Object.entries(value)) {
          const field =
            this.fields1.find((o) => o.key === key) ||
            this.fields2.find((o) => o.key === key) ||
            this.fields3.find((o) => o.key === key) ||
            this.fields4.find((o) => o.key === key) ||
            this.fieldsBank.find((o) => o.key === key)
          if (field) {
            if (
              key === 'dob' ||
              key === 'campusCardValidDate' ||
              key === 'residenceCardValidDate'
            ) {
              if (val) {
                val = moment(val).format('YYYY-MM-DD')
              }
            }
            if (key === 'sex') {
              val = val ? 1 : 0
            }

            if (key === 'isActive') {
              this.isActive = val
            }
            if (key === 'language') {
              this.language = val
            }
            if (key === 'visaId') {
              this.visaId = val
            }

            field.value = val
          }
        }
        const language = this.fields3.find((o) => o.key === 'language')
        if (language.value === '日本' || language.value === '日本人') {
          this.fields3.find((o) => o.key === 'visaId').disable = true
          this.fields3.find((o) => o.key === 'residenceCardId').disable = true
          this.fields3.find((o) => o.key === 'residenceCardValidDate').disable = true
          this.visaId = ''
          this.fields3.find((o) => o.key === 'residenceCardId').value = ''
          this.fields3.find((o) => o.key === 'residenceCardValidDate').value = ''
        }
        this.oneTimePass = this.newPassword
        const payload = {
          id: value.id,
        }
        this.$store.dispatch('parttimer/getParttimerVisa', payload)
      },
      colleges (value) {
        const field = this.fields2.find((o) => o.key === 'collegeId')
        value.forEach((item) => {
          field.items.push({
            value: item.id,
            text: item.name,
          })
        })
      },
      listVisa: {
        handler: function (value) {
          const field = this.fields3.find((o) => o.key === 'visaId')
          value.map((item) => {
            if (item.type === '留学') {
              this.visaStudentId = item.id
            }
            field.items.push({
              value: item.id,
              text: item.type,
            })
          })
        },
        deep: true,
        immediate: true,
      },
      dataOcrSave (value) {
        if (value.name) {
          this.dialogOcr = false
          for (let [key, val] of Object.entries(value)) {
            const field =
              this.fields1.find(o => o.key === key) ||
              this.fields2.find(o => o.key === key) ||
              this.fields3.find(o => o.key === key) ||
              this.fields4.find(o => o.key === key)
            if (field) {
              if (
                key === 'dob' ||
                key === 'residenceCardValidDate'
              ) {
                if (val) {
                  val = moment(val).format('YYYY-MM-DD')
                }
              }
              if (key === 'sex') {
                val = val === '女' ? 0 : 1
              }
              field.value = val
            }
          }
        }
      },
      resetPassword (value) {
        if (value.success === 1) {
          this.oneTimePass = value.data.newPassword
        }
      },
      parttimerVisaList (value) {
        if (value.count > 0) {
          this.docsList = value.result
          const totalDocs = this.docsList.length
          const docsCheckList = []
          this.docsList.map((item) => {
            delete item.createdAt
            delete item.updatedAt
            if (item.isFulfiled === true) {
              docsCheckList.push(item)
            }
          })
          const totalChecked = docsCheckList.length
          this.lengthDocs = totalChecked + '/' + totalDocs
          this.processCount = Math.round((totalChecked / totalDocs) * 100)
        }
      },
      dialogOcr (value) {
        if (this.dialogOcr === true) {
          setTimeout(function () { this.dialogOcr = false }.bind(this), 300000)
        }
      },
      language (val) {
        if (val === '日本' || val === '日本人') {
          this.fields3.find((o) => o.key === 'visaId').disable = true
          this.fields3.find((o) => o.key === 'residenceCardId').disable = true
          this.fields3.find((o) => o.key === 'residenceCardValidDate').disable = true
          this.visaId = ''
          this.fields3.find((o) => o.key === 'residenceCardId').value = ''
          this.fields3.find((o) => o.key === 'residenceCardValidDate').value = ''
        } else {
          this.fields3.find((o) => o.key === 'visaId').disable = false
          this.fields3.find((o) => o.key === 'residenceCardId').disable = false
          this.fields3.find((o) => o.key === 'residenceCardValidDate').disable = false
        }
      },
      visaId (val) {
        if (val === this.visaStudentId) {
          this.fields2.find((o) => o.key === 'collegeId').disable = false
          this.fields2.find((o) => o.key === 'campusCardValidDate').disable = false
        } else {
          this.fields2.find((o) => o.key === 'collegeId').disable = true
          this.fields2.find((o) => o.key === 'campusCardValidDate').disable = true
          this.fields2.find((o) => o.key === 'collegeId').value = null
          this.fields2.find((o) => o.key === 'campusCardValidDate').value = ''
        }
      },
    },

    created () {
      if (localStorage.getItem('user')) {
        this.userType = JSON.parse(localStorage.getItem('user')).detail.type
      }
      this.$store.dispatch('parttimer/getParttimerDetail', {
        id: this.$route.params.id,
      })
      this.$store.dispatch('visa/getVisa')
      this.$store.dispatch('parttimer/getCollege')
    },

    mounted () {
      // Define the rule globally
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })
      extend('katakanaType', {
        validate (value) {
          // regex check characters Katakana
          return /^[ｧ-ﾝﾞﾟ]+$/.test(value)
        },
        message: this.$t('validator.katakanaHaftWidthInvalid'),
      })
      extend('requiredTypeName', {
        validate (value) {
          // regex check characters Hiragana, Katakana, Kanji
          return /^[ぁ-んァ-ン一-龥 A-Z]+$/.test(value)
        },
        message: this.$t('validator.requiredTypeName'),
      })
      extend('email', {
        ...email,
        message: this.$t('validator.email_invalid'),
      })

      extend('number', {
        validate (value) {
          return /^\+?(0|[1-9]\d*)$/.test(value)
        },
        message: this.$t('validator.must_be_number'),
      })
      extend('min', {
        ...min,
        message: this.$t('validator.password_invalid'),
      })
      extend('dateDob', {
        validate (value) {
          return /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(value)
        },
        message: this.$t('生年月日は正確な形式で入力してください。'),
      })
      extend('dateResidenceCard', {
        validate (value) {
          return /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(value)
        },
        message: this.$t('存在しない年月日が入力されています。'),
      })
      extend('password', {
        params: ['target'],
        validate (value, target) {
          if (typeof target === 'object') return value === target[0]
          else if (typeof target === 'string' || typeof target === 'number') {
            return value === target
          }
        },
        message: this.$t('validator.confirm_password_invalid'),
      })
      extend('rangeDate', {
        validate (value) {
          if (moment(value).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) { return false }
          return true
        },
        message: this.$t('過去の日付を入力してください。'),
      })
    },
    methods: {
      closeDialogFile () {
        this.dialogCheckFile = false
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      hanldleUpdateLengFile (length) {
        this.lengthFiles = length + '/6'
        this.processCountFile = Math.round((length / 6) * 100)
      },
      onSuccess () {
        this.alertCopy.text = this.$t('messages.copied')
      },
      onError () {
        this.alertCopy.text = this.$t('messages.uncopy')
      },
      handleResetValidate (value) {
        return (value.customerValidate = '')
      },
      selectDocs (value) {
        const payload = {
          visaId: this.visaId,
          id: this.parttimeEmployee.id,
        }
        this.handleResetValidate(value)
        this.$store.dispatch('parttimer/getParttimerVisa', payload)
      },
      saveValueDate (value) {
        this.$refs['menu' + value.key][0].save(value.value)
        if (value.key === 'residenceCardValidDate') {
          this.handleResetValidate(value)
        }
      },
      deleteItem () {
        const payload = {
          id: this.$route.params.id,
        }
        this.$store.dispatch('parttimer/delete', payload)
      },
      cancelSubmit () {
        this.showButton = false
        this.username = this.parttimeEmployee.username
          ? this.parttimeEmployee.username
          : ''

        for (let [key, val] of Object.entries(this.parttimeEmployee)) {
          const field =
            this.fields1.find((o) => o.key === key) ||
            this.fields2.find((o) => o.key === key) ||
            this.fields3.find((o) => o.key === key)
          if (field) {
            if (
              key === 'dob' ||
              key === 'campusCardValidDate' ||
              key === 'residenceCardValidDate'
            ) {
              if (val) {
                val = moment(val).format('YYYY-MM-DD')
              }
            }
            if (key === 'sex') {
              val = val ? 1 : 0
            }
            field.value = val
          }
        }
      },
      onSubmit () {
        this.action = ''
        if (this.checkValidate()) {
          return
        }
        let payload = {}
        this.age = this.$refs.datedob[0].value
        this.residenceCardAvailable =
          this.$refs.dateresidenceCardValidDate[0].value
            ? this.$refs.dateresidenceCardValidDate[0].value
            : ''
        this.campusCardAvailable = this.$refs.datecampusCardValidDate[0].value
          ? this.$refs.datecampusCardValidDate[0].value
          : ''

        if (this.rePasswordCheck) return
        if (this.password.length > 0) {
          payload = {
            id: this.$route.params.id,
            password: this.password,
            confirmPassword: this.rePassword,
            manageId: this.fields1.find((o) => o.key === 'manageId').value,
            name: this.fields1.find((o) => o.key === 'name').value,
            furiganaName: this.fields1.find((o) => o.key === 'furiganaName')
              .value,
            dob: this.fields1.find((o) => o.key === 'dob').value,
            sex: this.fields1.find((o) => o.key === 'sex').value,
            email: this.fields1.find(o => o.key === 'email').value,
            contactPhone: this.fields1.find((o) => o.key === 'contactPhone')
              .value,
            age: this.age,
            contactAddress: this.fields1.find((o) => o.key === 'contactAddress')
              .value,
            serviceInfo: this.fields2.find((o) => o.key === 'serviceInfo').value,
            collegeId: this.fields2.find((o) => o.key === 'collegeId').value,
            campusCardValidDate: this.fields2.find((o) => o.key === 'campusCardValidDate').value
              ? this.fields2.find((o) => o.key === 'campusCardValidDate').value
              : '',
            campusCardAvailable: this.campusCardAvailable.toString(),
            language: this.language,
            visaId: this.visaId,
            residenceCardId: this.fields3.find((o) => o.key === 'residenceCardId')
              .value,
            residenceCardValidDate: this.fields3.find(
              (o) => o.key === 'residenceCardValidDate',
            ).value,
            residenceCardAvailable: this.residenceCardAvailable.toString(),
            isActive: this.fields1.find((o) => o.key === 'isActive').value,
          }
        } else {
          payload = {
            id: this.$route.params.id,
            manageId: this.fields1.find((o) => o.key === 'manageId').value,
            name: this.fields1.find((o) => o.key === 'name').value,
            furiganaName: this.fields1.find((o) => o.key === 'furiganaName')
              .value,
            dob: this.fields1.find((o) => o.key === 'dob').value,
            sex: this.fields1.find((o) => o.key === 'sex').value,
            email: this.fields1.find(o => o.key === 'email').value,
            contactPhone: this.fields1.find((o) => o.key === 'contactPhone')
              .value,
            age: this.age,
            contactAddress: this.fields1.find((o) => o.key === 'contactAddress')
              .value,
            serviceInfo: this.fields2.find((o) => o.key === 'serviceInfo').value,
            collegeId: this.fields2.find((o) => o.key === 'collegeId').value,
            campusCardValidDate: this.fields2.find(
              (o) => o.key === 'campusCardValidDate',
            ).value,
            campusCardAvailable: this.campusCardAvailable.toString(),
            language: this.language,
            visaId: this.visaId,
            residenceCardId: this.fields3.find((o) => o.key === 'residenceCardId')
              .value,
            residenceCardValidDate: this.fields3.find(
              (o) => o.key === 'residenceCardValidDate',
            ).value,
            residenceCardAvailable: this.residenceCardAvailable.toString(),
            isActive: this.fields1.find((o) => o.key === 'isActive').value,
          }
        }
        this.$store.dispatch('parttimer/update', payload)
      },
      hasErrors (errors) {
        return errors.length > 0
      },
      checkValidate () {
        this.reloadError()
        let error = false
        const validateNameTypeRegex = /^[ぁ-んァ-ン一-龥 A-Z]+$/
        const validateKatakanaRegex = /^[ｧ-ﾝﾞﾟ]+$/
        if (!this.username?.trim()) {
          this.checkUserName = this.$t('validator.required')
          error = true
        }
        if (
          this.password?.trim() &&
          this.password?.trim() !== this.rePassword?.trim()
        ) {
          this.rePasswordCheck = this.$t(
            '確認のためパスワードを再度入力してください。',
          )
          error = true
        }
        if (!this.fields1[1].value?.trim()) {
          this.fields1[1].customerValidate = this.$t('validator.required')
          error = true
        }
        if (!validateNameTypeRegex.test(this.fields1[1].value)) {
          error = true
        }
        if (!this.fields1[2].value?.trim()) {
          this.fields1[2].customerValidate = this.$t('validator.required')
          error = true
        }
        if (!validateKatakanaRegex.test(this.fields1[2].value)) {
          this.fields1[2].customerValidate = this.$t('validator.katakanaHaftWidthInvalid')
          error = true
        }
        if (!this.fields1[3].value?.trim()) {
          this.fields1[3].customerValidate = this.$t('validator.required')
          error = true
        } else if (
          !/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(
            this.fields1[3].value,
          )
        ) {
          error = true
        } else if (
          moment(this.fields1[3].value).format('YYYY-MM-DD') >=
          moment().format('YYYY-MM-DD')
        ) {
          error = true
        }
        if (!this.language) {
          this.fields3[0].customerValidate = this.$t('validator.required')
          error = true
        }
        if (!this.fields3[1].value) {
          this.fields3[1].customerValidate = this.$t('validator.required')
          error = true
        }
        if (!this.fields3[2].value?.trim()) {
          this.fields3[2].customerValidate = this.$t('validator.required')
          error = true
        }
        if (!this.fields3[3].value?.trim()) {
          this.fields3[3].customerValidate = this.$t('validator.required')
          error = true
        } else if (
          !/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(
            this.fields3[3].value,
          )
        ) {
          error = true
        }
        return error
      },
      expiryDate (dateString) {
        var expiration = moment(dateString).format('YYYY-MM-DD')
        var currentDate = moment().format('YYYY-MM-DD')
        var days = moment(expiration).diff(currentDate, 'days')
        if (!days) {
          days = 0
          return days
        }
        return days
      },
      checkDate (evt) {
        const field = this.fields.find((o) => {
          if (
            o.key === 'dob' ||
            o.key === 'campusCardValidDate' ||
            o.key === 'residenceCardValidDate'
          ) {
            return o
          }
        })
        if (
          (evt.keyCode >= 48 && evt.keyCode <= 57) ||
          (evt.keyCode >= 96 && evt.keyCode <= 105) ||
          evt.keyCode === 231
        ) {
          evt = evt || window.event

          if (field.value) {
            var size = field.value.length
            if (size >= 10) field.value = field.value.slice(0, -1)
            if (
              (size === 4 && field.value > moment().year()) ||
              (size === 7 &&
                (Number(field.value.split('-')[1]) > 12 ||
                  Number(field.value.split('-')[1]) < 1)) ||
              (size === 10 &&
                (Number(field.value.split('-')[2]) > 31 ||
                  Number(field.value.split('-')[1]) < 1))
            ) {
              field.value = ''
              // add alert funct
              return
            }

            if (
              (size === 4 && field.value < moment().year()) ||
              (size === 7 && Number(field.value.split('-')[1]) < 13)
            ) {
              field.value += '-'
            }
          }
        }
      },
      /** Methods popup bank */
      openDialogBank () {
        this.dialogBank = true
        for (const [key, val] of Object.entries(this.parttimeEmployee)) {
          const field = this.fieldsBank.find((o) => o.key === key)
          if (field) {
            field.value = val
          }
        }
      },
      saveBank () {
        const payload = {
          id: this.$route.params.id,
          bank: this.fieldsBank.find((o) => o.key === 'bank').value,
          bankBranch: this.fieldsBank.find((o) => o.key === 'bankBranch').value,
          cardNumber: this.fieldsBank.find((o) => o.key === 'cardNumber').value,
          accountHolder: this.fieldsBank.find((o) => o.key === 'accountHolder')
            .value,
          reason: this.fieldsBank.find((o) => o.key === 'reason').value,
        }
        this.$store.dispatch('employee/updateBank', payload)
        setTimeout(() => {
          this.$store.dispatch('parttimer/getParttimerDetail', {
            id: this.$route.params.id,
          })
          this.dialogBank = false
        }, 500)
      },
      /** Methods popup check doc */
      saveDocs () {
        this.dialogCheckDoc = false
        const totalDocs = this.docsList.length
        const docsCheckList = []
        this.docsList.map((item) => {
          if (item.isFulfiled === true) {
            docsCheckList.push(item)
          }
        })
        const totalChecked = docsCheckList.length
        this.lengthDocs = totalChecked + '/' + totalDocs
        this.processCount = Math.round((totalChecked / totalDocs) * 100)
        const payload = {
          id: this.$route.params.id,
          results: this.docsList,
        }
        this.$store.dispatch('employee/updatePersionalVisa', payload)
      },
      reloadError () {
        this.checkUserName = ''
        this.checkPassword = ''
        this.rePasswordCheck = ''
        this.checkCollegeId = ''
        this.fields1.forEach((field) => {
          field.customerValidate = ''
        })
        this.fields3.forEach((field) => {
          field.customerValidate = ''
        })
      },
      resetPass () {
        const payload = {
          id: this.parttimeEmployee.id,
        }
        this.$store.dispatch('parttimer/resetPassword', payload)
      },
      handleShowDialogStatus (key) {
        this.fieldKey = key
        this.dialogStatus = true
      },
      hanldeStatus (status) {
        const item = this.fields1.find((o) => o.key === this.fieldKey)
        if (!status) {
          item.value = !item.value
        } else {
          this.isActive = !this.isActive
        }
        this.dialogStatus = false
        this.fieldKey = ''
      },
    },
  }
</script>
<style lang="scss" scoped>
.title-bank {
  color: #4262ff;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
}
.chip {
  width: 412px;
  height: 127px;
  border-radius: 12px;
  margin-left: 50px;
  font-size: 18px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }

  .v-icon {
    margin-left: 100px;
    background: rgba(197, 220, 250, 0.5);
    padding: 8% 14% 10% 14%;
    border-radius: 30px;
    color: gray;
  }
}
  .custom-margin-top {
    margin-top: 11px;
  }
  .disable-events {
  pointer-events: none
}
</style>
